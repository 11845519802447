import './src/styles/global.scss';
import './src/styles/global.css';
import '@fontsource/lato/300.css'; // Weight 500 with all styles included.
import '@fontsource/lato/400.css'; // Weight 500 with all styles included.
import '@fontsource/lato/400-italic.css'; // Select either normal or italic.

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

import wrapWithProvider from './src/wrapWithProvider';
export const wrapRootElement = wrapWithProvider;

// or:
// require('./src/styles/global.css')
