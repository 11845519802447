import React from 'react';
import axios from 'axios';
import config from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    setIsReady,
    signInFinish,
    signInStart,
    signOut,
    userSet,
} from '../../state/reducers/globalReducer';

const isBrowser = typeof window !== 'undefined';

interface Props {
    children: any;
}

const Auth = ({ children }: Props) => {
    // const [isWaitingForSignIn, setIsWaitingForSignIn] = React.useState(isBrowser && localStorage.getItem('isSignedIn') === 'true')
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isRemovedRef = React.useRef(false);

    let skipAuth = false;

    if (isBrowser) {
        if (window.location.href.toLowerCase().indexOf('/auth/island') > 0) {
            skipAuth = true;
        }
    }

    const dispatch = useAppDispatch();
    // const setUser = useCallback(
    //     (user) =>
    //         dispatch({
    //             type: `USER_SET`,
    //             user,
    //         }),
    //     [dispatch]
    // );

    // const setSignOut = useCallback(
    //     () =>
    //         dispatch({
    //             type: `SIGN_OUT`,
    //         }),
    //     [dispatch]
    // );

    // const setSignInStart = useCallback(
    //     () =>
    //         dispatch({
    //             type: `SIGN_IN_START`,
    //         }),
    //     [dispatch]
    // );

    // const setSignInError = useCallback(
    //     () =>
    //         dispatch({
    //             type: `SIGN_IN_ERROR`,
    //         }),
    //     [dispatch]
    // );

    // const setSignInFinish = useCallback(
    //     (token) =>
    //         dispatch({
    //             type: `SIGN_IN_FINISH`,
    //             token,
    //         }),
    //     [dispatch]
    // );

    const refreshSessionAsync = async () => {
        console.log('Refreshing session');
        if (skipAuth) {
            return;
        }
        // setSignInStart()
        try {
            const data = (
                await axios.get(`${config.apiBaseUrl}/account/refresh`, {
                    withCredentials: true,
                })
            ).data;

            if (!isRemovedRef.current) {
                dispatch(
                    userSet({
                        ...data.user,
                    })
                );
                dispatch(
                    signInFinish({
                        ...data.token,
                    })
                );
                // setSignInFinish({ ...data.token });
            }
        } catch (err) {
            console.log(err);
            if (!isRemovedRef.current) {
                localStorage.removeItem('isSignedIn');
                dispatch(signOut());
                // setSignOut();
            }
        }
    };

    React.useEffect(() => {
        return () => {
            isRemovedRef.current = true;
        };
    }, []);

    // Refresh every 30 minutes
    React.useEffect(() => {
        let refreshInterval: NodeJS.Timer | null = null;
        if (isSignedIn) {
            refreshInterval = setInterval(refreshSessionAsync, 1000 * 60 * 30);
        }

        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
                refreshInterval = null;
            }
        };
    }, [isSignedIn]);

    React.useEffect(() => {
        const isAuthenticated = () => {
            if (!isBrowser) {
                return false;
            }
            // console.log(localStorage.getItem('isSignedIn'))
            return localStorage.getItem('isSignedIn') === 'true';
        };
        // Check on startup if user is signed in
        if (isAuthenticated() && !isRemovedRef.current) {
            dispatch(signInStart());
            dispatch(setIsReady());
            refreshSessionAsync();
        } else {
            dispatch(setIsReady());
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (isSignedIn && isBrowser) {
            // console.log('Saving signed in state')
            localStorage.setItem('isSignedIn', 'true');
        }
    }, [isSignedIn]);

    return children;
};

export default Auth;
