import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IToken } from '../../interfaces/IToken';
import { IUser } from '../../interfaces/IUser';

// const isBrowser = typeof window !== 'undefined';

export type FormType = {
    notifierMethod: string;
    notifierName: string;
    notifierPersonalIdNumber: string;
    notifierEmail: string;
    notifierPhoneNumber: string;
    anonymity: any;
    hasAcceptedLegal: boolean;
    hasAcceptedOrganisationLegal: boolean;
    receiveEmailNotifications: boolean;
    notificationEmail: string;
    isSubmitted: boolean;
    formResult: any;
    fields: any;
};

// Define a type for the slice state
interface GlobalState {
    isReady: boolean;
    language: string;
    isSignedIn: boolean;
    isSigningIn: boolean;
    user: IUser | null;
    auth: {
        accessToken: string | null;
    };
    organisation: IOrganisation | null;
    form: FormType;
    anonymousUser: any;
    notifier: any;
    anonymousSignIn: {
        identificationNumber: string;
        password: string;
    };
}

// Define the initial state using that type
const initialState: GlobalState = {
    isReady: false,
    language: 'is',
    isSignedIn: false,
    isSigningIn: false, //isBrowser && localStorage.getItem('isSignedIn') === 'true',
    user: null,
    auth: {
        accessToken: null,
    },
    organisation: null,
    form: {
        notifierMethod: '1',
        notifierEmail: '',
        notificationEmail: '',
        notifierPhoneNumber: '',
        notifierName: '',
        notifierPersonalIdNumber: '',
        isSubmitted: false,
        formResult: null,
        fields: null,
        hasAcceptedLegal: false,
        hasAcceptedOrganisationLegal: false,
        receiveEmailNotifications: false,
        anonymity: true,
    },
    anonymousUser: null,
    notifier: null,
    anonymousSignIn: {
        identificationNumber: '',
        password: '',
    },
};

export const globalSlice = createSlice({
    name: 'global',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: () => {
        return initialState;
    },
    reducers: {
        languageSet: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setIsReady: (state) => {
            state.isReady = true;
        },
        signInStart: (state) => {
            state.isSigningIn = true;
            state.isReady = true;
        },
        signInError: (state) => {
            state.isSigningIn = false;
            state.isSignedIn = false;
        },
        signOut: (state) => {
            state.isSigningIn = false;
            state.isSignedIn = false;
            state.user = null;
            state.organisation = null;
        },
        signInFinish: (state, action: PayloadAction<IToken | null>) => {
            if (action.payload === null) {
                return {
                    ...state,
                    isSigningIn: false,
                    isSignedIn: false,
                    isReady: true,
                    auth: {
                        accessToken: null,
                    },
                };
            }
            return {
                ...state,
                isSigningIn: false,
                isSignedIn: action.payload !== null,
                auth: {
                    ...action.payload,
                },
            };
        },
        userSet: (state, action: PayloadAction<IUser | null>) => {
            if (action.payload === null) {
                return {
                    ...state,
                    user: null,
                };
            }
            return {
                ...state,
                user: {
                    ...action.payload,
                    hasUser: true,
                },
                language: action.payload.language,
            };
        },
        organisationSet: (state, action: PayloadAction<IOrganisation>) => {
            if (action.payload == null) {
                console.log('Setting org to null');
                return {
                    ...state,
                    organisation: null,
                };
            }
            let updatedUserOrganisations = null;

            if (
                state.user != null &&
                state.user.organisations != null &&
                state.user.organisations.length > 0
            ) {
                updatedUserOrganisations = [...state.user.organisations];
                for (let i = 0; i < updatedUserOrganisations.length; i++) {
                    if (
                        updatedUserOrganisations[i].organisationId ===
                        action.payload.id
                    ) {
                        updatedUserOrganisations[i] = {
                            ...updatedUserOrganisations[i],
                            organisationName: action.payload.name,
                        };
                        break;
                    }
                }
            }

            return {
                ...state,
                organisation: {
                    ...action.payload,
                },
                user:
                    state.user === null
                        ? null
                        : {
                              ...state.user,
                              organisations: updatedUserOrganisations,
                          },
            };
        },
        formSet: (state, action: PayloadAction<FormType>) => {
            return {
                ...state,
                form: {
                    ...action.payload,
                },
            };
        },
        anonymousUserSet: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                anonymousUser: action.payload,
            };
        },
        anonymousSignInSet: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                anonymousSignIn: {
                    ...action.payload,
                },
            };
        },
        notifierSet: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                notifier: action.payload,
            };
        },
    },
});

export const {
    languageSet,
    signInStart,
    signInError,
    signOut,
    signInFinish,
    setIsReady,
    userSet,
    organisationSet,
    formSet,
    anonymousUserSet,
    anonymousSignInSet,
    notifierSet,
} = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.global.value;

export default globalSlice.reducer;
