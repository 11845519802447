exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-auth-island-js": () => import("./../../../src/pages/auth/island.js" /* webpackChunkName: "component---src-pages-auth-island-js" */),
  "component---src-pages-create-index-en-js": () => import("./../../../src/pages/create/index.en.js" /* webpackChunkName: "component---src-pages-create-index-en-js" */),
  "component---src-pages-create-index-is-js": () => import("./../../../src/pages/create/index.is.js" /* webpackChunkName: "component---src-pages-create-index-is-js" */),
  "component---src-pages-create-index-js": () => import("./../../../src/pages/create/index.js" /* webpackChunkName: "component---src-pages-create-index-js" */),
  "component---src-pages-email-verification-email-verification-tsx": () => import("./../../../src/pages/email-verification/EmailVerification.tsx" /* webpackChunkName: "component---src-pages-email-verification-email-verification-tsx" */),
  "component---src-pages-email-verification-index-en-tsx": () => import("./../../../src/pages/email-verification/index.en.tsx" /* webpackChunkName: "component---src-pages-email-verification-index-en-tsx" */),
  "component---src-pages-email-verification-index-is-tsx": () => import("./../../../src/pages/email-verification/index.is.tsx" /* webpackChunkName: "component---src-pages-email-verification-index-is-tsx" */),
  "component---src-pages-email-verification-index-tsx": () => import("./../../../src/pages/email-verification/index.tsx" /* webpackChunkName: "component---src-pages-email-verification-index-tsx" */),
  "component---src-pages-en-report-index-js": () => import("./../../../src/pages/en/report/index.js" /* webpackChunkName: "component---src-pages-en-report-index-js" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-forgotpassword-index-en-js": () => import("./../../../src/pages/forgotpassword/index.en.js" /* webpackChunkName: "component---src-pages-forgotpassword-index-en-js" */),
  "component---src-pages-forgotpassword-index-is-js": () => import("./../../../src/pages/forgotpassword/index.is.js" /* webpackChunkName: "component---src-pages-forgotpassword-index-is-js" */),
  "component---src-pages-forgotpassword-index-js": () => import("./../../../src/pages/forgotpassword/index.js" /* webpackChunkName: "component---src-pages-forgotpassword-index-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-is-report-index-js": () => import("./../../../src/pages/is/report/index.js" /* webpackChunkName: "component---src-pages-is-report-index-js" */),
  "component---src-pages-legal-cookie-policy-index-en-js": () => import("./../../../src/pages/legal/cookie-policy/index.en.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-index-en-js" */),
  "component---src-pages-legal-cookie-policy-index-is-js": () => import("./../../../src/pages/legal/cookie-policy/index.is.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-index-is-js" */),
  "component---src-pages-legal-index-en-js": () => import("./../../../src/pages/legal/index.en.js" /* webpackChunkName: "component---src-pages-legal-index-en-js" */),
  "component---src-pages-legal-index-is-js": () => import("./../../../src/pages/legal/index.is.js" /* webpackChunkName: "component---src-pages-legal-index-is-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-organisation-cookie-policy-index-en-js": () => import("./../../../src/pages/legal/organisation/cookie-policy/index.en.js" /* webpackChunkName: "component---src-pages-legal-organisation-cookie-policy-index-en-js" */),
  "component---src-pages-legal-organisation-cookie-policy-index-is-js": () => import("./../../../src/pages/legal/organisation/cookie-policy/index.is.js" /* webpackChunkName: "component---src-pages-legal-organisation-cookie-policy-index-is-js" */),
  "component---src-pages-legal-organisation-dpa-index-en-js": () => import("./../../../src/pages/legal/organisation/dpa/index.en.js" /* webpackChunkName: "component---src-pages-legal-organisation-dpa-index-en-js" */),
  "component---src-pages-legal-organisation-dpa-index-is-js": () => import("./../../../src/pages/legal/organisation/dpa/index.is.js" /* webpackChunkName: "component---src-pages-legal-organisation-dpa-index-is-js" */),
  "component---src-pages-legal-organisation-index-js": () => import("./../../../src/pages/legal/organisation/index.js" /* webpackChunkName: "component---src-pages-legal-organisation-index-js" */),
  "component---src-pages-legal-organisation-privacy-policy-index-en-js": () => import("./../../../src/pages/legal/organisation/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-legal-organisation-privacy-policy-index-en-js" */),
  "component---src-pages-legal-organisation-privacy-policy-index-is-js": () => import("./../../../src/pages/legal/organisation/privacy-policy/index.is.js" /* webpackChunkName: "component---src-pages-legal-organisation-privacy-policy-index-is-js" */),
  "component---src-pages-legal-organisation-terms-of-use-index-en-js": () => import("./../../../src/pages/legal/organisation/terms-of-use/index.en.js" /* webpackChunkName: "component---src-pages-legal-organisation-terms-of-use-index-en-js" */),
  "component---src-pages-legal-organisation-terms-of-use-index-is-js": () => import("./../../../src/pages/legal/organisation/terms-of-use/index.is.js" /* webpackChunkName: "component---src-pages-legal-organisation-terms-of-use-index-is-js" */),
  "component---src-pages-legal-privacy-policy-index-en-js": () => import("./../../../src/pages/legal/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-index-en-js" */),
  "component---src-pages-legal-privacy-policy-index-is-js": () => import("./../../../src/pages/legal/privacy-policy/index.is.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-index-is-js" */),
  "component---src-pages-legal-terms-of-use-index-en-js": () => import("./../../../src/pages/legal/terms-of-use/index.en.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-index-en-js" */),
  "component---src-pages-legal-terms-of-use-index-is-js": () => import("./../../../src/pages/legal/terms-of-use/index.is.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-index-is-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-report-index-js": () => import("./../../../src/pages/report/index.js" /* webpackChunkName: "component---src-pages-report-index-js" */),
  "component---src-pages-resetpassword-index-en-js": () => import("./../../../src/pages/resetpassword/index.en.js" /* webpackChunkName: "component---src-pages-resetpassword-index-en-js" */),
  "component---src-pages-resetpassword-index-is-js": () => import("./../../../src/pages/resetpassword/index.is.js" /* webpackChunkName: "component---src-pages-resetpassword-index-is-js" */),
  "component---src-pages-resetpassword-index-js": () => import("./../../../src/pages/resetpassword/index.js" /* webpackChunkName: "component---src-pages-resetpassword-index-js" */),
  "component---src-pages-signin-index-en-js": () => import("./../../../src/pages/signin/index.en.js" /* webpackChunkName: "component---src-pages-signin-index-en-js" */),
  "component---src-pages-signin-index-is-js": () => import("./../../../src/pages/signin/index.is.js" /* webpackChunkName: "component---src-pages-signin-index-is-js" */),
  "component---src-pages-signin-index-js": () => import("./../../../src/pages/signin/index.js" /* webpackChunkName: "component---src-pages-signin-index-js" */),
  "component---src-pages-signout-index-en-js": () => import("./../../../src/pages/signout/index.en.js" /* webpackChunkName: "component---src-pages-signout-index-en-js" */),
  "component---src-pages-signout-index-is-js": () => import("./../../../src/pages/signout/index.is.js" /* webpackChunkName: "component---src-pages-signout-index-is-js" */),
  "component---src-pages-signout-index-js": () => import("./../../../src/pages/signout/index.js" /* webpackChunkName: "component---src-pages-signout-index-js" */),
  "component---src-pages-signup-index-en-js": () => import("./../../../src/pages/signup/index.en.js" /* webpackChunkName: "component---src-pages-signup-index-en-js" */),
  "component---src-pages-signup-index-is-js": () => import("./../../../src/pages/signup/index.is.js" /* webpackChunkName: "component---src-pages-signup-index-is-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */)
}

