import React from "react"
import { Provider } from "react-redux"
import Auth from './components/Auth'

// import createStore from "../createStore"

import { store } from './state/store'

const wrapper = ({ element }) => {
    // const store = createStore()
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return (
        <Provider store={store}>
            <Auth>
                {element}
            </Auth>
        </Provider>
    )
}

export default wrapper
