interface IConfig {
    s3FilesUrl: string;
    version: string;
    apiBaseUrl: string;
    isProduction: boolean;
    googleAnalytics: string;
}

interface IConfigMap {
    production: IConfig;
    beta: IConfig;
    staging: IConfig;
    local: IConfig;
}

const baseConfig = {
    s3FilesUrl: 'https://tilkynna-files.s3.amazonaws.com',
    version: '1',
    // mqtt: {
    //     identityPoolId: 'eu-west-1:8e6a5207-128a-4c92-98fe-a1be8beb3e49',
    //     host: 'a2pz46fb5qjt2w-ats.iot.eu-west-1.amazonaws.com',
    //     region: 'eu-west-1',
    // },
};

const prodConfig = {
    ...baseConfig,
    apiBaseUrl: 'https://api.tilkynna.is',
    isProduction: true,
    googleAnalytics: 'UA-79171115-1',
};

const betaConfig = {
    ...baseConfig,
    apiBaseUrl: 'https://preprodapi.tilkynna.is',
    isProduction: true,
    googleAnalytics: 'UA-79171115-1',
};

const stagingConfig = {
    ...baseConfig,
    apiBaseUrl: 'https://stagingapi.tilkynna.is',
    isProduction: false,
    googleAnalytics: 'UA-79171115-2',
};

const localConfig = {
    ...baseConfig,
    apiBaseUrl: 'https://localhost:5001', //'https://localapi.tilkynna.is',
    isProduction: false,
    googleAnalytics: 'UA-79171115-2',
};

const configMap: IConfigMap = {
    production: prodConfig,
    beta: betaConfig,
    staging: stagingConfig,
    local: localConfig,
};

// console.log('WAT', process.env);

const config: IConfig = configMap[process.env.GATSBY_ENV || 'production'];

// console.log(process.env.REACT_APP_ENV)

export default config;
